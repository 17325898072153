import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Footer from "./footer";

export default function ContactUs() {
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        subject: '',
        textarea: '',
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formData.from_name.trim()) newErrors.from_name = "A név kitöltése kötelező!";
        if (!formData.from_email.trim() || !/\S+@\S+\.\S+/.test(formData.from_email))
            newErrors.from_email = "Érvényes email címet adj meg!";
        if (!formData.subject.trim()) newErrors.subject = "Telefonszám kitöltése kötelező!";
        if (!formData.textarea.trim()) newErrors.textarea = "Kérjük, írj néhány részletet!";
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            emailjs
                .sendForm('service_7xlqk1s', 'template_nolkzer', e.target, 'GW4NAzZYkUPUS7xPe')
                .then(
                    (result) => {
                        alert('Üzenet elküldve!');
                        setFormData({ from_name: '', from_email: '', subject: '', textarea: '' });
                    },
                    (error) => {
                        console.error('Hiba:', error.text);
                    }
                );
        }
    };

    return (
        <div className='Contmain'>
            <div className="ContactContent">
                <div className="welcomeText">
                    <h1>Kapcsolatba lépni egyszerű!</h1>
                    <p>
                        Köszönjük, hogy felkerested weboldalunkat! Ha bármilyen kérdésed van, szeretnél információt kérni a termékeinkről, vagy egyedi ajánlatot kérnél, ne habozz kapcsolatba lépni velünk! A lehető leggyorsabban válaszolunk minden megkeresésre, de maximum 24 órán belül biztosan visszajelzünk.<br /><br />
                        <strong></strong> <a href="mailto:support@profifahaz.hu">Fodor Attila</a> <br />
                        <strong>Email:</strong> <a href="mailto:support@profifahaz.hu">support@profifahaz.hu</a> <br />
                        <strong>Telefon:</strong> <a href="tel:+36205245794">+36 20 524 5794</a><br />
                    </p>
                    <p>
                        Munkatársaink gyorsan és professzionálisan segítenek! Kérjük, töltsd ki az alábbi űrlapot, hogy kapcsolatba léphessünk veled, és a legjobb megoldásokat kínálhassuk.
                    </p>
                </div>

                <div className="formWrapper">
                    <form className="form" onSubmit={handleSubmit}>
                        <label>Teljes név</label>
                        <input
                            type="text"
                            name="from_name"
                            value={formData.from_name}
                            onChange={handleChange}
                            className={errors.from_name ? 'error' : ''}
                        />
                        {errors.from_name && <small className="error-message">{errors.from_name}</small>}

                        <label>E-mail</label>
                        <input
                            type="email"
                            name="from_email"
                            value={formData.from_email}
                            onChange={handleChange}
                            className={errors.from_email ? 'error' : ''}
                        />
                        {errors.from_email && <small className="error-message">{errors.from_email}</small>}

                        <label>Telefonszám</label>
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            className={errors.subject ? 'error' : ''}
                        />
                        {errors.subject && <small className="error-message">{errors.subject}</small>}

                        <label>Részletek</label>
                        <textarea
                            name="textarea"
                            value={formData.textarea}
                            onChange={handleChange}
                            className={errors.textarea ? 'error' : ''}
                        />
                        {errors.textarea && <small className="error-message">{errors.textarea}</small>}

                        <input type="submit" value="Küldés" />
                    </form>
                </div>
                <div className='FooterMain'>
                { <Footer/>}
            </div>
            </div>
         
        </div>
    );
}
